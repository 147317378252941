<template>  
  <div>
    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('MinhasEntregas')}}
        </h3>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" align="center">
            <v-progress-circular
              slot="append"
              indeterminate
              color="primary"
              v-if="loading">
            </v-progress-circular>

            <v-btn
              @click="novo"
              color="success"
              v-if="!loading && perfil != 'EMBARCADOR'">
              <v-icon>
                mdi-plus
              </v-icon>
              {{$i18n.t('Adicionar')}}
            </v-btn>

            <v-card-title>
                <v-row>

                <v-col cols="5" sm="5">
                  <div class="text--primary">
                    <v-text-field
                      ref="dataInicio"
                      v-model="dataInicio"
                      :label="$i18n.t('DataInicio')"
                      required
                      v-mask="'##/##/####'">
                    </v-text-field>
                  </div>
                </v-col>

                <v-col cols="5" sm="5">
                  <div class="text--primary">
                    <v-text-field
                      ref="dataFim"
                      v-model="dataFim"
                      :label="$i18n.t('DataFim')"
                      v-mask="'##/##/####'"
                      required>
                    </v-text-field>
                  </div>
                </v-col>

                <v-col cols="2" sm="2">
                  <v-btn
                    @click="filtra"
                    color="success"
                    :small="mobile"
                    v-if="!loading && perfil != 'EMBARCADOR'">
                    <v-icon>
                      mdi-calendar-search
                    </v-icon>
                  </v-btn>
                </v-col>
                
              </v-row>
              
            </v-card-title>

            <v-responsive max-width="100%">

              <v-data-table :mobile-breakpoint="0"
                dense
                :headers="headers"
                :items="documentos"
                :options.sync="options"
                :server-items-length="totalDocumentos"
                item-key="id"
                :loading="loading"
                :items-per-page="10"
                :footer-props="{
                  'items-per-page-text': $i18n.t('DocumentosPorPagina'),
                  'items-per-page-options': [10, 15, 20, 30, 50, 100],
                  'pageText': '{0}-{1} de {2}'
                }"
                @pagination="pagination"
                @update:sort-by="sortBy"
                @update:sort-desc="sortDesc">

                <template v-slot:body.prepend>
                  <tr>
                    <td>
                      <v-text-field
                        v-model="filtro.ocorrenciaSituacao"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="filtro.numero"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="filtro.ocorrenciaData"
                        type="text"
                        @change="reload">
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="filtro.ocorrenciaTipo"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="filtro.ocorrencia"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                    
                    <td>
                      <v-text-field
                        v-model="filtro.dataEmissao"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="filtro.remetente"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="filtro.destinatario"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="filtro.numeroPedido"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="filtro.chave"
                        type="text"
                        class="text-field-filtro"
                        @change="reload">
                      </v-text-field>
                    </td>
                  </tr>
                </template>

                <template v-slot:item="{ item }">
                  <tr :style="'cursor: pointer;background:' + (item.cor ? item.cor : 'white')" @click="edita(item)">
                    <td>{{ item.ocorrenciaSituacao }}</td>
                    <td>{{ item.numero }}</td>
                    <td>{{ item.ocorrenciaData }}</td>
                    <td>{{ item.ocorrenciaTipo }}</td>
                    <td>{{ item.ocorrencia }}</td>
                    <td>{{ item.dataEmissao }}</td>
                    <td>{{ item.remetente }}</td>
                    <td>{{ item.destinatario }}</td>
                    <td>{{ item.numeroPedido }}</td>
                    <td>{{ item.chave }}</td>
                    <td>
                      <div v-if="perfil == 'EMBARCADOR'" class="link" @click="edita(item)">
                        {{$i18n.t('Visualizar')}}
                      </div>
                      <div v-if="perfil != 'EMBARCADOR'" class="link" @click="edita(item)">
                        {{$i18n.t('Editar')}}
                      </div>  
                    </td>
                  </tr>
                </template>
              </v-data-table>
              
            </v-responsive>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        search: '',
        perfil: '',
        documentos: [],
        filtro: {
          ocorrenciaSituacao: '',
          ocorrenciaTipo: '',
          ocorrenciaData: '',
          ocorrencia: '',
          numero: '',
          dataEmissao: '',
          remetente: '',
          destinatario: '',
          numeroPedido: '',
          chave: ''
        },
        dataInicio: '',
        dataFim: '',
        totalDocumentos: 0,
        options: {},
        headers: [
          { text: this.$i18n.t('Situacao'), value: 'ocorrenciaSituacao' },
          {
            text: this.$i18n.t('NumeroNF'),
            align: 'start',
            sortable: true,
            value: 'numero'
          },
          { 
            text: this.$i18n.t('DataOcorrencia'),
            value: 'ocorrenciaData',
            width: "140px",
            fixed: true
          },
          { text: this.$i18n.t('TipoOcorrencia'), value: 'ocorrenciaTipo' },
          { text: this.$i18n.t('Ocorrencia'), value: 'ocorrencia' },          
          { 
            text: this.$i18n.t('DateEmissaoNF'),
            value: 'dataEmissao',
            width: "140px",
            fixed: true
          },
          { text: this.$i18n.t('Remetente'), value: 'remetente' },
          { text: this.$i18n.t('Destinatario'), value: 'destinatario' },
          { text: this.$i18n.t('NumeroTransporte'), value: 'numeroPedido' },
          { text: this.$i18n.t('Chave'), value: 'chave' },
          { text: '', value: 'id', sortable: false }
        ],
        filter: {
          page: 1,
          itemsPerPage: 2
        }
      }
    },
    computed: {
      mobile() {
        return window.innerWidth <= 800;
      }
    },
    methods: {
      load() {

        if (this.dataInicio == null || this.dataInicio == '')
          this.$refs.dataInicio.focus();
        
        else if (this.dataFim == null || this.dataFim == '')
          this.$refs.dataFim.focus();
        
        else {

          this.loading = true;

          const { sortBy, sortDesc, page, itemsPerPage } = this.options;

          let data = this.filtro;
          data.dataInicio = this.dataInicio;
          data.dataFim = this.dataFim;
          data.offset = (page - 1) * itemsPerPage;
          data.limit = itemsPerPage;
          data.sortBy = (sortBy ? sortBy : 'numero') + (sortDesc.length > 0 && sortDesc[0] ? ' DESC' : '');

          this.$http.post('/api/doc/documentos/filtra', data)
            .then((req) => {
                this.documentos = req.data.lista;
                this.totalDocumentos = req.data.total;
            })
            .catch((e) => {
              this.$msg.erro(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });

        }
      },
      pagination(pagination) {
        if (pagination.page != this.filter.page || this.filter.itemsPerPage != pagination.itemsPerPage) {
          this.filter.page = pagination.page;
          this.filter.itemsPerPage = pagination.itemsPerPage;
          this.load();
        }
      },
      sortBy() {
        // this.options.page = 1;
        // this.load();
      },
      sortDesc() {
        // this.options.page = 1;
        this.load();
      },
      reload() {
        this.options.page = 1;
        this.load();
      },
      filtra() {
        this.load();
      },
      novo() {
        this.$router.push('/documento/add');
      },
      edita(documento) {
        this.$router.push('/documento/' + documento.id);
      },
      formataData(texto) {
        try {
          let s = texto.split(' ');
          let d = s[0].split('/');
          let h = s[1].split(':');
          let data = new Date(Number(d[2]), Number(d[1]) - 1, Number(d[0]), Number(h[0]), Number(h[1]));
          return data;
        } catch (e) {
          return null;
        }
      },
      comparaData(data1, data2) {
        let d1 = this.formataData(data1);
        let d2 = this.formataData(data2);
        return d1 < d2 ? -1 : 1;
      },
      parseData(hoje) {
        
        let mes = hoje.getMonth() + 1;
        if (mes < 10)
          mes = '0' + mes;

        let dia = hoje.getDate();
        if (dia < 10)
          dia = '0' + dia;

        // return dia + '-' + String(mes) + "-" + hoje.getFullYear();
        return dia + '/' + String(mes) + "/" + hoje.getFullYear();
      },
      carregaDatas() {
        var hoje = new Date();
        this.dataInicio = this.parseData(new Date(hoje.getFullYear(), hoje.getMonth(), 1));
        this.dataFim = this.parseData(new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0));
      }
    },
    mounted() {
      this.perfil = this.$auth.info().perfil;
      this.carregaDatas();
      this.load();
    }
  }
</script>
<style>
  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
  }
  .theme--dark.v-data-table tbody tr:nth-of-type(even) {
      background-color: rgba(0, 0, 0, .5);
  }
  .text-field-filtro {
    font-size: 14px;
  }
</style>